'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useNavigate } from 'utils/navigate';
import Border from './common/Border';
import { H2Text, LargeText } from './common/Typography';
import VerticalSpace from './common/VerticalSpace';
import {
  copyrightAndTopStyle,
  copyrightStyle,
  footerItemsStyle,
  footerStyle,
  pageLinksStyle,
  pageLinkStyle,
  shareIconsStyle,
  shareIconStyle,
  snsIconStyle,
  topStyle,
} from './Footer.css';

export default function Footer() {
  const { navigateToInternal, navigateToExternal } = useNavigate();

  return (
    <footer className={footerStyle}>
      <div className={footerItemsStyle}>
        <H2Text>Official SNS</H2Text>
        <VerticalSpace size="40" />
        <div className={snsIconStyle}>
          <Image
            className={shareIconStyle}
            src="/images/ic_x.svg"
            alt="x"
            width={50}
            height={51}
            onClick={() => navigateToExternal('x')}
          />
          <Image
            className={shareIconStyle}
            src="/images/ic_youtube.svg"
            alt="youtube"
            width={71}
            height={50}
            onClick={() => navigateToExternal('youtube')}
          />
        </div>
        <VerticalSpace size="60" />
        <div className={pageLinksStyle}>
          <div className={pageLinkStyle} onClick={() => navigateToExternal('shop')}>
            公式ショップ
          </div>
          <div className={pageLinkStyle} onClick={() => navigateToInternal('products')}>
            商品情報
          </div>
          <div className={pageLinkStyle} onClick={() => navigateToExternal('card-list')}>
            カード一覧
          </div>
          <div className={pageLinkStyle} onClick={() => navigateToInternal('appraisal')}>
            独自の鑑定機能
          </div>
          <div className={pageLinkStyle} onClick={() => navigateToInternal('top/news')}>
            ニュース
          </div>
          <div className={pageLinkStyle} onClick={() => navigateToInternal('top/questions')}>
            よくある質問
          </div>
          <div className={pageLinkStyle} onClick={() => navigateToInternal('company')}>
            会社概要
          </div>
        </div>
        <VerticalSpace size="72" />
        <Image
          src="/images/img_cnp_footer_logo.png"
          alt="ロゴ"
          width={89}
          height={44}
          onClick={() => navigateToInternal('top')}
        />
        <VerticalSpace size="60" />
        <Border />
        <div className={shareIconsStyle}>
          <LargeText>share</LargeText>
          <Image
            className={shareIconStyle}
            src="/images/ic_x.svg"
            alt="x"
            width={42}
            height={44}
            onClick={() => {
              navigateToExternal('x-share');
            }}
          />
          <Image
            className={shareIconStyle}
            src="/images/ic_line.svg"
            alt="x"
            width={47}
            height={47}
            onClick={() => {
              navigateToExternal('share-to-line');
            }}
          />
        </div>
        <Border />
        <VerticalSpace size="60" />
        <div className={pageLinkStyle} onClick={() => navigateToInternal('policy/privacy-policy')}>
          プライバシーポリシー
        </div>
        <VerticalSpace size="26" />
        <div
          className={pageLinkStyle}
          onClick={() => navigateToInternal('policy/social-media-policy')}
        >
          ソーシャルメディアポリシー
        </div>
        <VerticalSpace size="26" />
        <div className={pageLinkStyle} onClick={() => navigateToInternal('policy/inquiry')}>
          お問い合わせ
        </div>
        <VerticalSpace size="80" />
        <div className={copyrightAndTopStyle}>
          <div className={copyrightStyle}>
            <Image src="/images/ic_monolith.svg" alt="モノリス" width={84} height={41} />
            <div>© 2024 CNP / モノリス</div>
          </div>
          <Link className={topStyle} href="#" scroll={true}>
            <Image src="/images/ic_arrow_top.svg" alt="top" width={15} height={20} />
            <LargeText>TOP</LargeText>
          </Link>
        </div>
      </div>
    </footer>
  );
}

import "../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fheader.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA8WUz26jMBDG7zzFSKtK7cEV%2FZek9NJXceyBTGtsy3YEacW7VzaQwDbsZi%2FbE3hmxPebb8bcHsrGv1EOnxmAJG8VPxRQKmxfMkhPJsmhCGR0Ac40McwVVZpRwNr3tQy1jIm3vQ9UHpgwOqAOBXjLBbIthgZRx4otF%2B%2BVM3stmTDKuAKaHQWMKculJF0VcJfbFu5zmxB2SNUuFLAaztZ46mFKajGpBmMLyONbQzLs4gfyq3j8YKQltimQv2Rddtt3e5e6FXvnI4A1pAO6Sf7%2B59z4lUj%2FpxkPfzHjcW6GNhon2ad5dquMeP8T2Uj%2BG9zYmOBKXMcgsFR6M3dpsi1L7awum50wal%2BnGSjygflwUDg2N3E%2FGVlzV5Fmif8h8R%2FV1kntNKwn28Lj0OI3htmiCOx9BiiNDqwZDNgalcxanscmSQ5MCstQwGbG9HzegSX180tdcVsc125qwBhbBuT%2FOoGzZF32WqMkDl44RA1cS7iuecuGzVmv1ra9SVKzyZ%2B7jz5wF6JSd6p%2BHqq%2FXdKTNd0CBOkTxOZiiOGncAnCtP7ovusXZNyuLuu%2BAP%2BM24O8BQAA%22%7D"
export var hamburgerButtonStyle = 'yfwsji3';
export var headerInverseStyle = 'yfwsji2';
export var headerLogoStyle = 'yfwsji1';
export var headerStyle = 'yfwsji0';
export var navItemImageStyle = 'yfwsji8';
export var navItemStyle = 'yfwsji7';
export var navStyle = 'yfwsji6';
export var sideMenuActiveStyle = 'yfwsji5';
export var sideMenuHiddenStyle = 'yfwsji4';
export var socialLinkImageStyle = 'yfwsjib';
export var socialLinkItemStyle = 'yfwsjia';
export var socialLinksStyle = 'yfwsji9';
import "../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FFooter.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61Sy2rDMBC8%2BysWSqGFKkjOs8qx9DuKYm1stbYkZBk7Kfn3IikpTgqhB%2BODYTTszM7O7IOxQVXHisJ3BiBVa2tx4LCvcdhmEP9EKoeFV0ZzKEzdNTq8iFqVmiiPTcuhQO3RBXgniq%2FSmU5LUpjaOA4P72%2FhC49WSKl0yWFF7QA5tVGjV9JXHBilj9vslM0ulthElq7mAzRiIGdovdoECyPN%2FH%2BazvR3BEthOawXN6Pn16NLp2QkOyWJx8bWwiNJy7QcHFoU%2Fil%2FAbZ3z4HoTE%2Fi5MU5t8RNWH6rtohqlxNQSuMaRnvSqiNyYKs0JEI9qrLyHNaJViuNpDpjbLYMmMfBE4mFcSJF0GmJLjCjlc61QcgalUIYOVlOFulymTw3wpVKc8iZHYBeia3S2nfsrKew89m1Xu0PpDDao%2FYcWisKJDv0PaK%2BV%2BrNRKWOgTA6OuL5rvSmCK9TCs5%2Fmzeu1Z9uaBNqcfoBzhmRA10EAAA%3D%22%7D"
export var copyrightAndTopStyle = '_11xihzh7';
export var copyrightStyle = '_11xihzh8';
export var footerItemsStyle = '_11xihzh1';
export var footerStyle = '_11xihzh0';
export var pageLinkStyle = '_11xihzh4';
export var pageLinksStyle = '_11xihzh3';
export var shareIconStyle = '_11xihzh6';
export var shareIconsStyle = '_11xihzh5';
export var snsIconStyle = '_11xihzh2';
export var topStyle = '_11xihzh9';
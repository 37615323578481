'use client';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import { useNavigate } from 'utils/navigate';
import {
  hamburgerButtonStyle,
  headerInverseStyle,
  headerLogoStyle,
  headerStyle,
  navItemImageStyle,
  navItemStyle,
  navStyle,
  sideMenuActiveStyle,
  sideMenuHiddenStyle,
  socialLinkImageStyle,
  socialLinkItemStyle,
  socialLinksStyle,
} from './header.css';

type HamburgerButton = {
  imagePath: string;
  alt: string;
  width: number;
  height: number;
};

const hamburgerButtonOpen: HamburgerButton = {
  imagePath: '/images/ic_hamburger_open.svg',
  alt: 'menu_open',
  width: 40,
  height: 28,
};

const hamburgerButtonOpenInverse: HamburgerButton = {
  imagePath: '/images/ic_hamburger_open_inverse.svg',
  alt: 'menu_open',
  width: 40,
  height: 28,
};

const hamburgerButtonClose: HamburgerButton = {
  imagePath: '/images/ic_hamburger_close.svg',
  alt: 'menu_close',
  width: 40,
  height: 28,
};

const hamburgerButtonCloseInverse: HamburgerButton = {
  imagePath: '/images/ic_hamburger_close_inverse.svg',
  alt: 'menu_close',
  width: 40,
  height: 28,
};

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const pathname = usePathname();
  const isInverse = ['/company', '/appraisal'].includes(pathname);

  const hamburgerButton = isMenuOpen
    ? isInverse
      ? hamburgerButtonCloseInverse
      : hamburgerButtonClose
    : isInverse
      ? hamburgerButtonOpenInverse
      : hamburgerButtonOpen;
  const sideMenuStyle = isMenuOpen ? sideMenuActiveStyle : sideMenuHiddenStyle;
  const toggleMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const { navigateToInternal, navigateToExternal } = useNavigate();

  return (
    <>
      <header className={isInverse ? headerInverseStyle : headerStyle}>
        <div
          className={headerLogoStyle}
          onClick={() => {
            setIsMenuOpen(false);
            navigateToInternal('top');
          }}
        >
          <Image
            src={`/images/${isInverse ? 'img_cnp_logo_inverse.png' : 'img_cnp_logo.png'}`}
            alt="CNPトレカ"
            width={143}
            height={23}
          />
        </div>
        <div onClick={toggleMenu} className={hamburgerButtonStyle}>
          <Image
            src={hamburgerButton.imagePath}
            alt={hamburgerButton.alt}
            width={hamburgerButton.width}
            height={hamburgerButton.height}
          />
        </div>
      </header>
      <nav className={sideMenuStyle}>
        <ul className={navStyle}>
          <li
            className={navItemStyle}
            onClick={() => {
              navigateToExternal('shop');
            }}
          >
            公式ショップ
            <Image
              src="/images/ic_new_window_black.svg"
              alt="external link"
              width={21}
              height={21}
              className={navItemImageStyle}
            />
          </li>
          <li
            className={navItemStyle}
            onClick={() => {
              navigateToInternal('products');
              setIsMenuOpen(false);
            }}
          >
            商品情報
          </li>
          <li
            className={navItemStyle}
            onClick={() => {
              navigateToExternal('card-list', false);
              setIsMenuOpen(false);
            }}
          >
            カード一覧
          </li>
          <li
            className={navItemStyle}
            onClick={() => {
              navigateToInternal('appraisal');
              setIsMenuOpen(false);
            }}
          >
            独自の鑑定機能
          </li>
          <li
            className={navItemStyle}
            onClick={() => {
              navigateToInternal('top/news');
              setIsMenuOpen(false);
            }}
          >
            ニュース
          </li>
          <li
            className={navItemStyle}
            onClick={() => {
              navigateToInternal('top/questions');
              setIsMenuOpen(false);
            }}
          >
            よくある質問
          </li>
          <li
            className={navItemStyle}
            onClick={() => {
              navigateToInternal('company');
              setIsMenuOpen(false);
            }}
          >
            会社概要
          </li>
        </ul>
        <div className={socialLinksStyle}>
          <div className={socialLinkItemStyle}>official SNS</div>
          <div className={socialLinkItemStyle} onClick={() => navigateToExternal('x')}>
            <Image
              src="/images/ic_x.svg"
              alt="公式X"
              width={32}
              height={33}
              className={socialLinkImageStyle}
            />
          </div>
          <div className={socialLinkItemStyle} onClick={() => navigateToExternal('youtube')}>
            <Image
              src="/images/ic_youtube.svg"
              alt="公式YouTube"
              width={54}
              height={35}
              className={socialLinkImageStyle}
            />
          </div>
        </div>
      </nav>
    </>
  );
}
